/* You can add global styles to this file, and also import other style files */
app-root {
  height: 100% !important;
}

app-login, app-register, app-forgot, app-dashboard, app-personal-information, pdf-page {
  width: 100% !important;
}

.error-text {
  color: #7e0508 !important;
  font-size: large !important;
}

.btn-error-ok {
  color: #7e0508 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  font-size: small !important;
}

.warning-text {
  color: #985712 !important;
  font-size: large !important;
}

.btn-warning-ok {
  color: #985712 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  font-size: small !important;
}

.success-text {
  color: #0b650d !important;
  font-size: large !important;
}

.btn-success-ok {
  color: #0b650d !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  font-size: small !important;
}

.sidebar-enabled[data-sidebar=on] .wrapper {
  margin-right: unset !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):hover:not(.btn-text), .btn.btn-hover-primary:not(:disabled):not(.disabled).active, .show > .btn.btn-hover-primary.dropdown-toggle, .show .btn.btn-hover-primary.btn-dropdown {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

#kt_aside .nav-link:hover {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.offcanvas-mobile {
  left: -5000px !important;
}

.btn-new {
  background-color: #f67a78 !important;
  border-color: #f67a78 !important;
  color: white !important;
}

.btn-new:hover {
  background-color: #f34b48 !important;
  border-color: #f34b48 !important;
  color: white !important;
}